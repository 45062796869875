exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-minecraft-changes-index-tsx": () => import("./../../../src/pages/minecraft/changes/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-changes-index-tsx" */),
  "component---src-pages-minecraft-discord-index-tsx": () => import("./../../../src/pages/minecraft/discord/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-discord-index-tsx" */),
  "component---src-pages-minecraft-index-tsx": () => import("./../../../src/pages/minecraft/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-index-tsx" */),
  "component---src-pages-minecraft-news-index-tsx": () => import("./../../../src/pages/minecraft/news/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-news-index-tsx" */),
  "component---src-pages-minecraft-play-index-tsx": () => import("./../../../src/pages/minecraft/play/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-play-index-tsx" */),
  "component---src-pages-minecraft-whitelist-index-tsx": () => import("./../../../src/pages/minecraft/whitelist/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-whitelist-index-tsx" */)
}

